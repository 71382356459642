// Redux functionality

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  charities: [],
  writerOnboarding: false,
  savedCardSelected: "",
  errorMsg: "",
};

// Create reducers in slice.

export const contributeSlice = createSlice({
  name: "contribute",
  initialState,
  reducers: {
    setCharities: (state, { payload }) => {
      state.charities = payload;
      state.errorMsg = "";
    },
    contributeError: (state, { payload }) => {
      state.errorMsg = payload;
    },
    setWriterOnboarding: (state, { payload }) => {
      state.writerOnboarding = payload;
      state.errorMsg = "";
    }, 
    setSavedCardSelected: (state, { payload }) => {
      state.savedCardSelected = payload;
    }, 
  },
});

// Create actions

export const {
  setCharities,
  contributeError,
  setWriterOnboarding,
  setSavedCardSelected,
} = contributeSlice.actions;

// Add payload to state

export const selectContribute = (state) => state.contribute;

export default contributeSlice.reducer;

// Function that fetches data from API.
export function fetchCharities() {
  return async (dispatch) => {
    api(`/charitytargets/`)
      .then((res) => {
        dispatch(setCharities(res.data.results));
      })
      .catch((err) => {
        dispatch(contributeError(err.response.data))
      });
  };
}

// Function that fetches data from API.
export function getOnboardingStatusByBookSlug(bookSlug) {
  return async (dispatch) => {
    api(`/onboarding/get_onboarding_status_from_book_slug/`, {
      method: "POST",
      data: { book_slug: bookSlug },
    })
      .then((res) => {
        dispatch(setWriterOnboarding(res.data));
      })
      .catch((err) => {
        dispatch(contributeError(err.response.data))
      });
  };
}
