// Redux functionality for Top Rated Life Learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";
import { fetchMyOwnLifeLearnings } from "./myLifeLearningsSlice";

export const initialState = {
  hasErrors: false,
  myMinibooks: [],
  miniBookSlug: "",
  errorMsg: {},
};

// Create reducers in slice.

export const myMiniBooksSlice = createSlice({
  name: "myMinibooks",
  initialState,
  reducers: {
    getSavedMinibooks: (state, { payload }) => {
      state.myMinibooks = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getMyOwnMinibooks: (state, { payload }) => {
      state.myMinibooks = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteSavedMinibook: (state, { payload }) => {
      state.myMinibooks = state.myMinibooks.filter((minibook) => minibook.slug !== payload);
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteOneMinibook: (state, { payload }) => {
      state.myMinibooks = state.myMinibooks.filter(
        (minibook) => minibook.slug !== payload
      );
    },
    setMinibookSlug: (state, { payload }) => {
      state.miniBookSlug = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    myMinibooksError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getSavedMinibooks,
  getMyOwnMinibooks,
  deleteSavedMinibook,
  deleteOneMinibook,
  setMinibookSlug,
  myMinibooksError,
} = myMiniBooksSlice.actions;

// Add payload to state

export const selectMyMinibooks = (state) => state.myMinibooks;

export default myMiniBooksSlice.reducer;

export function fetchSavedMinibooks(querystring="") {
  return async (dispatch) => {
    api(`/minibooks/saved_by_me/${querystring}`)
    .then(res => {
      dispatch(getSavedMinibooks(res.data.results));
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}

export function fetchMyOwnMinibooks(querystring="") {
  return async (dispatch) => {
    api(`/minibooks/my_own/${querystring}`)
    .then(res => {
      dispatch(getMyOwnMinibooks(res.data.results));
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}

export function removeMySavedMinibook(slug, handleClose) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/remove_saved/`, {
      method: "PATCH",
    })
    .then(() => {
      dispatch(deleteSavedMinibook(slug));
      if (handleClose)
        handleClose();
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}

export function deleteMyMinibook(slug, handleClose) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/`, {
      method: "DELETE",
    })
    .then(() => {
      dispatch(deleteOneMinibook(slug));
      if (handleClose)
        handleClose();
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}

export function delayedDeleteMyMinibook(slug, handleClose) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/delayed_delete/`, {
      method: "POST",
    })
    .then(() => {
      dispatch(fetchMyOwnMinibooks());
      dispatch(fetchMyOwnLifeLearnings());
      if (handleClose)
        handleClose();
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}

export function undoDelayedDeleteMyMinibook(slug) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/delayed_delete/`, {
      method: "POST",
      data: {"undo": "true"},
    })
    .then(() => {
      dispatch(fetchMyOwnMinibooks());
    })
    .catch(err => {
      dispatch(myMinibooksError(err.message));
    })
  };
}