// Redux functionality for authentication.

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { hideAll } from "./modalSlice";
import i18n from "i18next";
import Cookie from "universal-cookie";
import { resetIsStaff } from "./profileSlice";

const cookies = new Cookie();

const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET.replace('"', "");
const openIDUrl = process.env.REACT_APP_API_ROOT + "/openid/";

// Non-global axios instance
const axiosInstance = axios.create();

export const initialState = {
  isAuthenticated: false,
  access_token: sessionStorage.getItem("access_token"),
  isLoading: false,
  user: null,
  nextURL: "",
};

// Create reducers in slice.

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoading: (state, { payload }) => {
        state.isLoading = payload;
    },
    loginSuccess: (state, { payload }) => {
      sessionStorage.setItem("access_token", payload.access_token);
      sessionStorage.setItem("id_token", payload.id_token);
      sessionStorage.setItem(
        "expiration_date",
        Date.now() + payload.expires_in * 1000
      );
      cookies.set("logged_in", true, { maxAge: 60 * 60 * 24 * 30, sameSite:'strict' });
      state.isAuthenticated = true;
    },
    loginFail: (state) => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("expiration_date");
      cookies.set("logged_in", false, {sameSite:'strict'});
      state.user = null;
      state.isAuthenticated = false;
    },
    silentRenew: (state, { payload }) => {
      sessionStorage.setItem("access_token", payload.access_token);
      sessionStorage.setItem("id_token", payload.id_token);
      sessionStorage.setItem(
        "expiration_date",
        Date.now() + payload.expires_in * 1000
      );
      cookies.set("logged_in", true, { maxAge: 60 * 60 * 24 * 30, sameSite:'strict' });
      state.isAuthenticated = true;
    },
    logOutSuccess: (state) => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("expiration_date");
      cookies.set("logged_in", false, {sameSite:'strict'});
      state.user = null;
      state.isAuthenticated = false;
    },
    silentRenewFailure: (state) => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("expiration_date");
      cookies.set("logged_in", false, {sameSite:'strict'});
      state.user = null;
      state.isAuthenticated = false;
    },
    authError: (state) => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("id_token");
      sessionStorage.removeItem("expiration_date");
      cookies.set("logged_in", false, {sameSite:'strict'});
      state.user = null;
      state.isAuthenticated = false;
    },
    setNextURL: (state, { payload }) => {
      state.nextURL = payload;
    },
  },
});

// Create actions

export const {
  userLoading,
  loginSuccess,
  loginFail,
  silentRenew,
  logOutSuccess,
  silentRenewFailure,
  authError,
  setNextURL,
} = authSlice.actions;

// Add payload to state

export const auth = (state) => state.auth;

export default authSlice.reducer;

// Add delay so that intercepted requests depending on this value have time to finish
const delayedStopLoading = (dispatch) => {
  setTimeout(() =>{
      dispatch(userLoading(false))
    }, 1000
  )
}

export function loginUser(fields, navigate, nextURL, setStatus) {
  return async (dispatch) => {
    dispatch(userLoading(true));
    let fieldsCopy = { ...fields }
    fieldsCopy.password = btoa(fields.password);

    let formBody = [];
    for (let property in fieldsCopy) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(fieldsCopy[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    axiosInstance(openIDUrl + "token/", {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
      },
      data: formBody,
      withCredentials: true,
    })
      .then((res) => {
        dispatch(loginSuccess(res.data));
        if (navigate && nextURL) {
          navigate(nextURL);
          dispatch(setNextURL(""));
        }
        dispatch(hideAll());
        delayedStopLoading(dispatch);
      })
      .catch((err) => {
        dispatch(loginFail(err.message));
        setStatus(i18n.t("login>Invalid email or password. Try again, please."))
        delayedStopLoading(dispatch);
      });
  };
}

// Unsets refresh_token in httponly cookie by querying 'unset-cookie' endpoint
export function logOutUser() {
  return async (dispatch) => {
    axiosInstance
      .post(openIDUrl + "unset-cookie/", {}, { withCredentials: true })
      .then(() => {})
      .catch(() => {
        //console.log(err)
      });
    dispatch(logOutSuccess());
    dispatch(resetIsStaff());
  };
}

// Renews access token using refresh_token in cookie
export const renewWithCookie = () => {
  return async (dispatch) => {
    dispatch(userLoading(true));
    axios
      .post(openIDUrl + "token/", `grant_type=refresh_token`, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
        },
        withCredentials: true,
      })
      .then((res) => {
        sessionStorage.setItem("access_token", res.data.access_token);
        dispatch(silentRenew(res.data));
        delayedStopLoading(dispatch);
      })
      .catch(() => {
        dispatch(silentRenewFailure());
        delayedStopLoading(dispatch);
      });
  };
};
