// Redux functionality for Top Rated Life Learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  lifeLearnings: [],
  selectedLifeLearning: {},
  errorMsg: "",
};

// Create reducers in slice.

export const lifeLearningsSlice = createSlice({
  name: "lifeLearnings",
  initialState,
  reducers: {
    getLifeLearnings: (state, action) => {
      state.lifeLearnings = action.payload;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    getTopRated: (state, action) => {
      state.lifeLearnings = action.payload;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    postLifeLearning: (state, action) => {
      state.lifeLearnings = [...state.lifeLearnings, action.payload];
      state.hasErrors = false;
      state.errorMsg = "";
    },
    getSelectedLifeLearning: (state, { payload }) => {
      state.selectedLifeLearning = payload;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    deleteLifeLearning: (state, { payload }) => {
      state.lifeLearnings = state.lifeLearnings.filter(
        (learning) => learning.id !== payload
      );
      state.hasErrors = false;
      state.errorMsg = "";
    },
    lifeLearningsError: (state, action) => {
      state.hasErrors = true;
      state.errorMsg = action.payload;
    },
  },
});

// Create actions

export const {
  getLifeLearnings,
  getTopRated,
  postLifeLearning,
  getSelectedLifeLearning,
  deleteLifeLearning,
  lifeLearningsError,
} = lifeLearningsSlice.actions;

// Add payload to state

export const selectLifeLearnings = (state) => state.lifeLearnings;

export default lifeLearningsSlice.reducer;

// Function that fetches data from API.

export function fetchLifeLearnings() {
  return async (dispatch) => {
    api(`/learnings/`)
      .then((res) => {
        dispatch(getLifeLearnings(res.data.results));
      })
      .catch((err) => {
        dispatch(lifeLearningsError(err.message));
      });
  };
}

export function fetchTopRatedLifeLearnings() {
  return async (dispatch) => {
    api(`/learnings/top_rated/`)
      .then((res) => {
        dispatch(getTopRated(res.data.results));
      })
      .catch((err) => {
        dispatch(lifeLearningsError(err.message));
      });
  };
}

export function postNewLifeLearning(fields, handleRequestSuccess) {
  return async (dispatch) => {
    api(`/learnings/`, {
      method: "POST",
      data: fields,
    })
      .then((res) => {
        dispatch(postLifeLearning(res.data));
        dispatch(getSelectedLifeLearning(res.data));
        handleRequestSuccess();
      })
      .catch((err) => {
        if (err.response.data.content)
          dispatch(lifeLearningsError(err.response.data.content));
        if (err.response.data.detail)
          dispatch(lifeLearningsError(err.response.data.detail));
      });
  };
}

export function removeLifeLearning(id) {
  return async (dispatch) => {
    api(`/learnings/${id}/`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(deleteLifeLearning(id));
      })
      .catch((err) => {
        dispatch(lifeLearningsError(err.message));
      });
  };
}