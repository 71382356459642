// Redux functionality to fetch profile info of the minibook's writer.
// Separate from the actual profile of the logged in user, so profiles won't get mixed up.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  writerProfile: {},
  hasErrors: false,
  errorMsg: {},
};

// Create reducers in slice.

export const writerProfileSlice = createSlice({
  name: "writerProfile",
  initialState,
  reducers: {
    getWriterUserProfile: (state, { payload }) => {
      state.writerProfile = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    writerUserProfileError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getWriterUserProfile,
  writerUserProfileError,
} = writerProfileSlice.actions;

// Add payload to state

export const selectWriterProfile = (state) => state.writerProfile;

export default writerProfileSlice.reducer;

// Function that fetches data from API.

export function fetchUserProfileById(profileID) {
  return async (dispatch) => {
    if (profileID==null)
      return;
    api(`/profiles/${profileID}/`)
    .then(res => {
      dispatch(getWriterUserProfile(res.data));
    })
    .catch(err => {
      dispatch(writerUserProfileError(err.message));
    })
  };
}
