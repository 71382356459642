// Redux functionality for Top Rated Life Learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  errorMsg: "",
  score: 1,
  learningRatings: {},
  bookRatings: {},
};

// Create reducers in slice.

export const ratingSlice = createSlice({
  name: "score",
  initialState,
  reducers: {
    postNewRating: (state, { payload }) => {
      state.score = payload;
      state.hasErrors = false;
    },
    ratingError: (state, {payload}) => {
      state.errorMsg = payload;
      state.hasErrors = true;
    },
    setLearningRatings: (state, { payload }) => {
      state.learningRatings = payload;
      state.hasErrors = false;
    },
    setBookRatings: (state, { payload }) => {
      state.bookRatings = payload;
      state.hasErrors = false;
    },
  },
});

// Create actions

export const { postNewRating, ratingError, setLearningRatings, setBookRatings } = ratingSlice.actions;

// Add payload to state

export const selectStarRating = (state) => state.score;

export default ratingSlice.reducer;

// Function that posts data to API.

export function postStarRating(id, score) {
  return async (dispatch) => {
    api(`/learnings/${id}/rate/`, {
      method: "POST",
      data: JSON.stringify({ score }),
    })
      .then(() => {
        dispatch(postNewRating());
      })
      .catch((err) => {
        dispatch(ratingError(err.message));
        //console.log(err.message);
      });
  };
}

export function giveStarRatingToBook(slug, score) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/rate/`, {
      method: "POST",
      data: JSON.stringify({ score }),
    })
      .then(() => {
        dispatch(postNewRating());
      })
      .catch((err) => {
        dispatch(ratingError(err.message));
        //console.log(err.message);
      });
  };
}

export function getMyLearningRatings() {
  return async (dispatch) => {
    api(`/learnings/my_ratings/`, {
      method: "GET",
    })
      .then((res) => {
        dispatch(setLearningRatings(res.data));
      })
      .catch((err) => {
        dispatch(ratingError(err.message));
      });
  };
}

export function getMyBookRatings() {
  return async (dispatch) => {
    api(`/minibooks/my_ratings/`, {
      method: "GET",
    })
      .then((res) => {
        dispatch(setBookRatings(res.data));
      })
      .catch((err) => {
        dispatch(ratingError(err.message));
      });
  };
}
