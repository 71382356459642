// Redux functionality to fetch one specific life learning which is related to selected minibook.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  relatedLifeLearning: {},
  relatedLifeLearningContent: "",
  hasErrors: false,
  errorMsg: {},
};

// Create reducers in slice.

export const relatedLifeLearningSlice = createSlice({
  name: "relatedLifeLearning",
  initialState,
  reducers: {
    getRelatedLifeLearning: (state, { payload }) => {
      state.relatedLifeLearning = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    addRelatedLifeLearning: (state, { payload }) => {
      state.relatedLifeLearningContent = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    relatedLifeLearningError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getRelatedLifeLearning,
  addRelatedLifeLearning,
  relatedLifeLearningError,
} = relatedLifeLearningSlice.actions;

// Add payload to state

export const selectRelatedLifeLearning = (state) => state.relatedLifeLearning;

export default relatedLifeLearningSlice.reducer;

export function fetchRelatedLifeLearning(learningID) {
  return async (dispatch) => {
    if (learningID==null)
      return;
    api(`/learnings/${learningID}/`)
    .then(res => {
      dispatch(getRelatedLifeLearning(res.data));
    })
    .catch(err => {
      dispatch(relatedLifeLearningError(err.message));
    })
  };
}
