// Redux functionality to fetch saved learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  learnings: [],
  errorMsg: {},
};

// Create reducers in slice.

export const savedLearningsSlice = createSlice({
  name: "savedLearnings",
  initialState,
  reducers: {
    getSavedLearnings: (state, { payload }) => {
      state.savedLearnings = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    savedLearningsError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getSavedLearnings,
  savedLearningsError,
} = savedLearningsSlice.actions;

// Add payload to state

export const selectSavedLearnings = (state) => state.savedLearnings;

export default savedLearningsSlice.reducer;

// Function that fetches data from API.

export function fetchSavedLearnings() {
  return async (dispatch) => {
    api(`/learnings/saved_by_me/`)
      .then((res) => {
        dispatch(getSavedLearnings(res.data.results));
      })
      .catch((err) => {
        dispatch(savedLearningsError(err.message));
      });
  };
}
