// Redux functionality for Top Rated Life Learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  myLifeLearnings: [],
  myLatestLifeLearning: {},
  oneLifeLearning: {},
  errorMsg: {},
};

// Create reducers in slice.

export const myLifeLearningsSlice = createSlice({
  name: "myLifeLearnings",
  initialState,
  reducers: {
    getMyLifeLearnings: (state, { payload }) => {
      state.myLifeLearnings = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getMyLatestLifeLearning: (state, { payload }) => {
      state.myLatestLifeLearning = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getMyOwnLifeLearnings: (state, { payload }) => {
      state.myLifeLearnings = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteLifeLearning: (state, { payload }) => {
      state.myLifeLearnings = state.myLifeLearnings.filter(
        (learning) => learning.id !== payload
      );
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteSavedLifeLearning: (state, { payload }) => {
      state.myLifeLearnings = state.myLifeLearnings.filter(
        (learning) => learning.id !== payload
      );
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getOneLifeLearning: (state, { payload }) => {
      state.oneLifeLearning = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    clearOneLearningFromState: (state) => {
      state.oneLifeLearning = {};
      state.hasErrors = false;
      state.errorMsg = {};
    },
    myLifeLearningError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getMyLifeLearnings,
  getMyOwnLifeLearnings,
  getMyLatestLifeLearning,
  deleteLifeLearning,
  deleteSavedLifeLearning,
  getOneLifeLearning,
  clearOneLearningFromState,
  myLifeLearningError,
} = myLifeLearningsSlice.actions;

// Add payload to state

export const selectMyLifeLearnings = (state) => state.myLifeLearnings;

export default myLifeLearningsSlice.reducer;

export function fetchMyLifeLearnings(querystring="") {
  return async (dispatch) => {
    api(`/learnings/saved_by_me/${querystring}`)
      .then((res) => {
        dispatch(getMyLifeLearnings(res.data.results));
      })
      .catch((err) => {
        dispatch(myLifeLearningError(err.message));
      });
  };
}

export function fetchMyOwnLifeLearnings(querystring="") {
  return async (dispatch) => {
    api(`/learnings/my_own/${querystring}`)
      .then((res) => {
        dispatch(getMyOwnLifeLearnings(res.data.results));
      })
      .catch((err) => {
        dispatch(myLifeLearningError(err.message));
      });
  };
}

export function deleteMyLifeLearning(id) {
  return async (dispatch) => {
    api(`/learnings/${id}/`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(deleteLifeLearning(id));
      })
      .catch((err) => {
        dispatch(myLifeLearningError(err.message));
      });
  };
}

export function removeMySavedLifeLearning(id, handleClose) {
  return async (dispatch) => {
    api(`/learnings/${id}/remove_saved/`, {
      method: "PATCH",
    })
      .then(() => {
        dispatch(deleteSavedLifeLearning(id));
        if (handleClose)
          handleClose();
      })
      .catch((err) => {
        dispatch(myLifeLearningError(err.message));
      });
  };
}

export function fetchMyLatestLifeLearning() {
  return async (dispatch) => {
    api(`/learnings/latest/`)
      .then((res) => {
        dispatch(getMyLatestLifeLearning(res.data));
      })
      .catch((err) => {
        dispatch(myLifeLearningError(err.message));
      });
  };
}
