// Redux functionality to fetch LifeLearning categories.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  categories: [],
  errorMsg: {},
};

// Create reducers in slice.

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    getCategories: (state, { payload }) => {
      state.categories = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    categoriesError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const { getCategories, categoriesError } = categoriesSlice.actions;

// Add payload to state

export const selectCategories = (state) => state.categories;

export default categoriesSlice.reducer;

// Function that fetches data from API.

export function fetchCategories() {
  return async (dispatch) => {
    api(`/categories/`)
    .then(res => {
      dispatch(getCategories(res.data.results));
    })
    .catch(err => {
      dispatch(categoriesError(err.message));
    })
  };
}
