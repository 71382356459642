import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";
import api from "../axios";

export const initialState = {
  msg: "",
  hasErrors: false,
  email: "",
};

export const resetPasswordSlice = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {
    sendEmailSuccess: (state, { payload }) => {
      state.msg = payload;
      state.hasErrors = false;
    },
    sendEmailFailure: (state, { payload }) => {
      state.msg = payload
      state.hasErrors = true;
    },
  },
});

export const {
  sendEmailSuccess,
  sendEmailFailure,
} = resetPasswordSlice.actions;

export const selectResetPassword = (state) => state.passwordReset;

export default resetPasswordSlice.reducer;

export function fetchEmail(email) {
  return async (dispatch) => {
    api(`/profiles/password_change_email_request/`, {
      method: "POST",
      data: JSON.stringify({ email }),
    })
      .then(() => {
        dispatch(sendEmailSuccess(i18n.t("reset-pw>Email sent. Check your email.")));
      })
      .catch((err) => {
        switch (err.response.status) {
          case 404:
            dispatch(sendEmailFailure(i18n.t(
              "reset-pw>There is not an active profile connected to this email address on Life Learnings forum. Please enter a valid email address.")
              ));
            break;
          case 400:
            dispatch(sendEmailFailure(i18n.t(
              "reset-pw>No email supplied. Please enter a valid email address."
            )));
            break;
          default:
            dispatch(sendEmailFailure(i18n.t("reset-pw>Error: ") + err.message));
            break;
        }
      });
  };
}
