// Redux functionality for Top Rated Life Learnings.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  charities: [],
  errorMsg: {},
};

// Create reducers in slice.

export const charitiesSlice = createSlice({
  name: "charities",
  initialState,
  reducers: {
    getCharities: (state, { payload }) => {
      state.charities = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },

    charitiesError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const { getCharities, charitiesError } = charitiesSlice.actions;

// Add payload to state

export const selectCharities = (state) => state.charities;
export default charitiesSlice.reducer;

export function fetchCharities(startDate, endDate) {
  return async (dispatch) => {
    api
      .post("/charitytargets/list_with_votes/", {
        "from_timestamp": startDate,
        "to_timestamp": endDate,
      })
      .then((res) => {
        res.data.sort((a, b) =>
          a.weighted_votes < b.weighted_votes
            ? 1
            : b.weighted_votes < a.weighted_votes
            ? -1
            : 0
        );

        dispatch(getCharities(res.data));
      })
      .catch((err) => {
        dispatch(charitiesError(err.message));
      });
  };
}
