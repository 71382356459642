// Redux functionality to fetch LifeLearning categories.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  onboardingStatus: false,
  onboardingMessage: "",
  accountInfo: {},
  contributionsToUser: [],
  onboardingRedirectUrl: "#",
  onboardingAuthorizationStatus: "",
  errorMsg: "",
};

// Create reducers in slice.

export const myAccountSlice = createSlice({
  name: "myaccount",
  initialState,
  reducers: {
    setOnboardingStatus: (state, { payload }) => {
      state.onboardingStatus = payload.onboarding_status;
      state.onboardingMessage = payload.message;
    },
    setAccountInfo: (state, { payload }) => {
      state.accountInfo = payload;
    },
    setContributionsToUser: (state, { payload }) => {
      state.contributionsToUser = payload;
    },
    setOnboardingRedirectUrl: (state, { payload }) => {
      state.onboardingRedirectUrl = payload;
    },
    setOnboardingAuthorizationStatus: (state, { payload }) => {
      state.onboardingAuthorizationStatus = payload;
    },
    myAccountError: (state, { payload }) => {
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  setOnboardingStatus,
  setAccountInfo,
  setContributionsToUser,
  setOnboardingRedirectUrl,
  setOnboardingAuthorizationStatus,
  myAccountError,
} = myAccountSlice.actions;

// Add payload to state

export const selectMyAccount = (state) => state.myaccount;

export default myAccountSlice.reducer;

// Function that fetches data from API.
export function getOnboardingStatus() {
  return async (dispatch) => {
    api(`/onboarding/get_onboarding_status/`)
      .then((res) => {
        dispatch(setOnboardingStatus(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(myAccountError(err.response.data));
      });
  };
}

// Note: Email attribute in response is not available when using Stripe test keys.
export function getAccountInfo() {
  return async (dispatch) => {
    api(`/onboarding/get_stripe_account_info/`)
      .then((res) => {
        dispatch(setAccountInfo(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(myAccountError(err.response.data));
      });
  };
}

export function fetchContributionsToUser() {
  return async (dispatch) => {
    api(`/contributions/get_authors_contributions/`)
      .then((res) => {
        dispatch(setContributionsToUser(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(myAccountError(err.response.data));
      });
  };
}
