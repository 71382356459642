// Redux functionality for minibook draft.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  minibookDraft: {},
  steps: {},
  errorMsg: {},
};

export const minibookDraftSlice = createSlice({
  name: "minibookDraft",
  initialState,
  reducers: {
    getMinibookDraft: (state, { payload }) => {
      state.minibookDraft = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setSteps: (state, { payload }) => {
      state.steps = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    minibookDraftError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

export const { getMinibookDraft, setSteps, minibookDraftError } = minibookDraftSlice.actions;

export const selectMinibookDraft = (state) => state.minibookDraft;

export default minibookDraftSlice.reducer;

export function fetchMiniBookDraftSteps(slug) {
  return async (dispatch) => {
    api.post(`/minibooks/${slug}/get_steps/`, {"steps": [1, 2, 3, 4, 5]})
      .then((res) => {
        dispatch(setSteps(res.data))
      })
      .catch((err) => {
        dispatch(minibookDraftError(err.message));
      });
  };
}