/*  Override of React lazy function that retries the request once on error.

    Used to force-refresh the page in case index.html is not synced
    with the latest hashed filename of the JS bundle. Non-synchronization 
    causes a ChunkLoadError which is fixed by a refresh.
*/

import { lazy } from "react";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem("page-has-been-force-refreshed", "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Refresh page once
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }

      // Throw other persisting errors so they show in the dev console
      throw error;
    }
  });

export default lazyWithRetry;
