// Redux functionality to fetch LifeLearning categories.

import { createSlice } from "@reduxjs/toolkit";
import store from "../store";
import api from "../axios";
//import { compareAvgRating } from "../components/utils/CompareAvgRating";
import constants from "../constants/AppConstants";

function CalcNumPages(count) {
  let numPages = Math.ceil(count / constants.SEARCHPAGESIZE);
  if (numPages > constants.SEARCHNUMPAGES) numPages = constants.SEARCHNUMPAGES;
  return numPages;
}

function GetLearningsUrlAndParams() {
  let search_query = selectSearch(store.getState()).search_query;
  let category = selectSearch(store.getState()).category;
  let by_writer = selectSearch(store.getState()).by_writer;
  let learningsPage = selectSearch(store.getState()).learningsPage;
  let params = "";
  let url = "";

  localStorage.getItem(constants.LEARNINGSSEARCHFILTERSTATE) ==
  constants.TOPRATED
    ? (url = `/learnings/top_rated/`)
    : (url = `/learnings/`);

  by_writer
    ? (params = `?page=${learningsPage}&username=${search_query}&category=${category}`)
    : (params = `?page=${learningsPage}&content=${search_query}&category=${category}`);
  return url + params;
}

function GetBooksUrlAndParams() {
  let search_query = selectSearch(store.getState()).search_query;
  let category = selectSearch(store.getState()).category;
  let by_writer = selectSearch(store.getState()).by_writer;
  let booksPage = selectSearch(store.getState()).booksPage;
  let params = "";
  let url = "";

  localStorage.getItem(constants.BOOKSSEARCHFILTERSTATE) == constants.TOPRATED
    ? (url = `/minibooks/top_rated/`)
    : (url = `/minibooks/`);

  by_writer
    ? (params = `?page=${booksPage}&username=${search_query}&category=${category}`)
    : (params = `?page=${booksPage}&search_query=${search_query}&category=${category}`);
  return url + params;
}

export const initialState = {
  search_query: "",
  category: "",
  by_writer: false,
  booksSearched: false,
  learningsSearched: false,
  learnings: [],
  minibooks: [],
  hasErrors: false,
  errorMsg: {},
  learningsPage: 1,
  booksPage: 1,
  hasNextLearningsPage: null,
  hasNextBooksPage: null,
  numLearningsPages: 0,
  numBooksPages: 0,
};

// Create reducers in slice.

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchQuery: (state, { payload }) => {
      state.search_query = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setByWriter: (state, { payload }) => {
      if (payload == "true") payload = true;
      if (payload == "false") payload = false;
      state.by_writer = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setHasNextLearningsPage: (state, { payload }) => {
      state.hasNextLearningsPage = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setHasNextBooksPage: (state, { payload }) => {
      state.hasNextBooksPage = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setLearningsPage: (state, { payload }) => {
      state.learningsPage = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setBooksPage: (state, { payload }) => {
      state.booksPage = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setNumLearningsPages: (state, { payload }) => {
      state.numLearningsPages = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setNumBooksPages: (state, { payload }) => {
      state.numBooksPages = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setBooksSearched: (state, { payload }) => {
      state.booksSearched = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setLearningsSearched: (state, { payload }) => {
      state.learningsSearched = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setToggle: (state, { payload }) => {
      if (payload == "true") payload = true;
      if (payload == "false") payload = false;
      state.toggle = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setLearnings: (state, { payload }) => {
      state.learnings = payload;
      state.hasErrors = false;
      state.errorMsg = payload;
    },
    setMiniBooks: (state, { payload }) => {
      state.minibooks = payload;
      state.hasErrors = false;
    },
    getLearningsError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    getMiniBooksError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  setSearchQuery,
  setCategory,
  setToggle,
  setByWriter,
  setBooksSearched,
  setLearningsSearched,
  setLearnings,
  setMiniBooks,
  setHasNextBooksPage,
  setHasNextLearningsPage,
  setLearningsPage,
  setBooksPage,
  setNumLearningsPages,
  setNumBooksPages,
  getLearningsError,
  getMiniBooksError,
} = searchSlice.actions;

// Add payload to state

export const selectSearch = (state) => state.search;

export default searchSlice.reducer;

// Function that fetches data from API.

export function fetchLearnings() {
  let learningsPage = selectSearch(store.getState()).learningsPage;
  let urlAndParams = GetLearningsUrlAndParams();
  return async (dispatch) => {
    api(urlAndParams)
      .then((res) => {
        dispatch(setLearnings(res.data.results));
        let count = res.data.count;
        dispatch(setNumLearningsPages(CalcNumPages(count)));
        // if learningsPage >=constants.SEARCHNUMPAGES we have reached the limit.
        if (
          res.data.next == null ||
          learningsPage >= constants.SEARCHNUMPAGES
        ) {
          dispatch(setHasNextLearningsPage(false));
        } else {
          dispatch(setHasNextLearningsPage(true));
        }
      })
      .catch((err) => {
        dispatch(getLearningsError(err.message));
      });
  };
}

export function fetchMiniBooks() {
  let booksPage = selectSearch(store.getState()).booksPage;
  let urlAndParams = GetBooksUrlAndParams();
  return async (dispatch) => {
    api(urlAndParams)
      .then((res) => {
        res.data.results = res.data.results.filter(
          (book) => book.status === "published"
        );
        dispatch(setMiniBooks(res.data.results));
        let count = res.data.count;
        dispatch(setNumBooksPages(CalcNumPages(count)));
        // if booksPage >= constants.SEARCHNUMPAGES we have reached the limit.
        if (res.data.next == null || booksPage >= constants.SEARCHNUMPAGES) {
          dispatch(setHasNextBooksPage(false));
        } else {
          dispatch(setHasNextBooksPage(true));
        }
      })
      .catch((err) => {
        dispatch(getMiniBooksError(err.message));
      });
  };
}
