// Redux functionality for Top Rated Mini Books.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";
import i18next from "i18next"

export const initialState = {
  hasErrors: false,
  minibooks: [],
  errorMsg: {},
};

export const miniBooksSlice = createSlice({
  name: "minibooks",
  initialState,
  reducers: {
    getMiniBooks: (state, { payload }) => {
      state.minibooks = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getTopRatedBooks: (state, { payload }) => {
      state.minibooks = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteOneMinibook: (state, { payload }) => {
      state.minibooks = state.minibooks.filter(
        (minibook) => minibook.slug !== payload
      );
      state.hasErrors = false;
      state.errorMsg = "";
    },
    miniBooksError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

export const {
  getMiniBooks,
  getTopRatedBooks,
  deleteOneMinibook,
  miniBooksError,
} = miniBooksSlice.actions;

export const selectMiniBooks = (state) => state.minibooks;

export default miniBooksSlice.reducer;

export function fetchMiniBooks(status) {
  return async (dispatch) => {
    api(`/minibooks/${status?"?status="+status:""}`)
    .then(res => {
      dispatch(getMiniBooks(res.data.results));
    })
    .catch(err => {
      console.log(err.message);
      dispatch(miniBooksError(err.message));
    })
  };
}

export function fetchTopRatedMiniBooks(status) {
  return async (dispatch) => {
    api(`/minibooks/top_rated/${status?"?status="+status:""}`)
    .then(res => {
      dispatch(getTopRatedBooks(res.data.results));
    })
    .catch(err => {
      console.log(err.message);
      dispatch(miniBooksError(err.message));
    })
  };
}

export function getStatusDictionary() {
  let res = {
    "published": i18next.t("myLifeLearnings>Published"),
    "draft": i18next.t("myLifeLearnings>Draft"),
    "delete_pending": i18next.t("myLifeLearnings>Delete Pending"),
  };
  return res;
}

export function removeMiniBook(slug) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(deleteOneMinibook(slug));
      })
      .catch((err) => {
        dispatch(miniBooksError(err.message));
      });
  };
}