// Redux functionality to control text-to-speech player state

import { createSlice } from "@reduxjs/toolkit";

// Constants
export const MAX_RATE_LOCAL = 2;
export const MAX_RATE_NON_LOCAL = 1.5;

export const initialState = {
  status: "stopped",
  rate: 1,
  pitch: 1,
  volume: 1,
  narrator: {},
  narrators: [],
  defaultNarrator: {},
  localNarrator: false,
  voicesLoaded: false,
  supported: false,
  TTSCurrentPage: 1,
};

// Create reducers in slice.

export const TTSSlice = createSlice({
  name: "textToSpeech",
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setRate: (state, { payload }) => {
      state.rate = payload;
    },
    setPitch: (state, { payload }) => {
      state.pitch = payload;
    },
    setVolume: (state, { payload }) => {
      state.volume = payload;
    },
    setNarrator: (state, { payload }) => {
      state.narrator = payload;
      // Update Rate attribute if switching to non-local narrator
      try {
        state.localNarrator = payload.localService;
      }
      catch(err){ // payload is undefined
        return;
      }
      if (state.localNarrator==false&&state.rate>MAX_RATE_NON_LOCAL)
        state.rate = MAX_RATE_NON_LOCAL;
    },
    setNarrators: (state, { payload }) => {
      state.narrators = payload;
    },
    setDefaultNarrator: (state, { payload }) => {
      state.defaultNarrator = payload;
    },
    setLocalNarrator: (state, { payload }) => {
      state.localNarrator = payload;
    },
    setSupported: (state, { payload }) => {
      state.supported = payload;
    },
    setVoicesLoaded: (state, { payload }) => {
      state.voicesLoaded = payload;
    },
    setTTSCurrentPage: (state, { payload }) => {
      state.TTSCurrentPage = payload;
    },
  },
});

// Create actions

export const {
  setStatus,
  setRate,
  setPitch,
  setVolume,
  setNarrator,
  setNarrators,
  setDefaultNarrator,
  setLocalNarrator,
  setSupported,
  setVoicesLoaded,
  setTTSCurrentPage,
} = TTSSlice.actions;

// Add payload to state

export const selectTTS = (state) => state.textToSpeech;

export default TTSSlice.reducer;

