/* Globally accessed Redux modal show states.

Used when inheritance is cumbersome or a modal needs to be accessed from
a non-parent component, ie. Terms of Use on Sign in -modal

A <Modal> component corresponding to a Redux state should only be defined once per page;
the NavbarModals component is a good place for global modals. Otherwise, the parent page
element works.

To pop up a modal, call dispatch(setXXX(true)) in an event. Set 'false' to hide that specific
modal, or HideAll() to close all Redux-controlled modals.
*/

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  showLogin: false, // navbar
  showSignIn: false, // navbat
  showTermsOfUse: false, // footer
  showPrivacyPolicy: false, // footer
  showEditBio: false, // create minibook form
  showPublishMinibook: false, // create minibook form
  showLoginOrCreateAccount: false, // navbar
  loginPromptCustomText: null,
  loginPromptCustomHeader: null,
};

// Create reducers in slice.

export const modalSlice = createSlice({
  name: "showModal",
  initialState,
  reducers: {
    hideAll: (state) => {
      state.showLogin = false;
      state.showSignIn = false;
      state.showTermsOfUse = false;
      state.showPrivacyPolicy = false;
      state.showEditBio = false;
      state.showPublishMinibook = false;
      state.showLoginOrCreateAccount = false;
      state.loginPromptCustomText = null;
      state.loginPromptCustomHeader = null;
    },
    setLogin: (state, { payload }) => {
      state.showLogin = payload;
    },
    setSignIn: (state, { payload }) => {
      state.showSignIn = payload;
    },
    setTermsOfUse: (state, { payload }) => {
      state.showTermsOfUse = payload;
    },
    setPrivacyPolicy: (state, { payload }) => {
      state.showPrivacyPolicy = payload;
    },
    setEditBioModal: (state, { payload }) => {
      state.showEditBio = payload;
    },
    setPublishMinibookModal: (state, { payload }) => {
      state.showPublishMinibook = payload;
    },
    setLoginOrCreateAccount: (state, { payload }) => {
      state.showLoginOrCreateAccount = payload;
    },
    setLoginPromptCustomText: (state, { payload }) => {
      state.loginPromptCustomText = payload;
    },
    setLoginPromptCustomHeader: (state, { payload }) => {
      state.loginPromptCustomHeader = payload;
    },
  },
});

// Create actions

export const {
  hideAll,
  setLogin,
  setSignIn,
  setTermsOfUse,
  setPrivacyPolicy,
  setEditBioModal,
  setPublishMinibookModal,
  setLoginOrCreateAccount,
  setLoginPromptCustomText,
  setLoginPromptCustomHeader,
} = modalSlice.actions;

// Add payload to state

export const selectShowModal = (state) => state.showModal;

export default modalSlice.reducer;
