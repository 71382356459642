// Redux functionality for reports.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  reports: [],
  errorMsg: {},
};

// Create reducers in slice.

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReports: (state, { payload }) => {
      state.reports = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteReport: (state, { payload }) => {
      state.reports = state.reports.filter((report) => report.id !== payload);
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setReportError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const { getReports, deleteReport, setReportError } = reportSlice.actions;

// Add payload to state

export const selectReports = (state) => state.reports;

export default reportSlice.reducer;

export function fetchReports() {
  return async (dispatch) => {
    api(`/reports/`)
      .then((res) => {
        dispatch(getReports(res.data.results));
      })
      .catch((err) => {
        dispatch(setReportError(err.message));
      });
  };
}

export function deleteOneReport(id) {
  return async (dispatch) => {
    api(`/reports/${id}/`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(deleteReport(id));
      })
      .catch((err) => {
        dispatch(setReportError(err.message));
      });
  };
}