// Redux functionality to fetch user profile info.
import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";
import i18n from "i18next";
import { logOutUser } from "./authSlice";

export const initialState = {
  profile: {},
  isStaff: false,
  hasErrors: false,
  errorMsg: "",
};

// Create reducers in slice.

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    getUserProfile: (state, { payload }) => {
      state.profile = payload;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    getUserProfileFailure: (state, { payload }) => {
      state.profile = {};
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    postNewUserProfile: (state, { payload }) => {
      state.profile = payload;
      state.hasErrors = false;
    },
    deleteProfile: (state) => {
      //state.profile = state.profile.filter(user => user.id !== payload);
      state.profile = {};
      state.hasErrors = false;
    },
    editUserProfile: (state, { payload }) => {
      state.profile = payload;
      state.hasErrors = false;
    },
    setIsStaff: (state, { payload }) => {
      state.isStaff = payload;
      state.hasErrors = false;
    },
    resetIsStaff: (state) => {
      state.isStaff = false;
      state.hasErrors = false;
    },
    userProfileError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getUserProfile,
  getUserProfileFailure,
  postNewUserProfile,
  editUserProfile,
  deleteProfile,
  setIsStaff,
  resetIsStaff,
  userProfileError,
} = profileSlice.actions;

// Add payload to state

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;

// Function that fetches data from API.

export function fetchUserProfile() {
  return async (dispatch) => {
    api(`/profiles/own_profile/`)
      .then((res) => {
        dispatch(getUserProfile(res.data));
        if (res.data.user_info.is_staff == true) {
          dispatch(setIsStaff(true));
        }
      })
      .catch((err) => {
        dispatch(logOutUser());
        dispatch(getUserProfileFailure(err.message));
      });
  };
}

export function registerNewUser(fields, setShow, setErrors) {
  let fieldsCopy = { ...fields }
  fieldsCopy.password1 = btoa(fields.password1);
  fieldsCopy.password2 = btoa(fields.password2);
  return async (dispatch) => {
    fieldsCopy.language_preference = i18n.language;
    api(`/profiles/register/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: fieldsCopy,
    })
      .then((res) => {
        dispatch(postNewUserProfile(res.data));
        setShow(true);
      })
      .catch((err) => {
        let errData = err.response.data
        console.log(errData)
        if (typeof errData === 'object' && errData !== null){
          setErrors(errData);
          dispatch(userProfileError(i18n.t("validation>There are errors in the form.")));
        } else {
          dispatch(userProfileError(errData));
          }
      });
  };
}

export function editUserInfo(id, fields, setShow, setErrors, enqueueSnackbar) {
  return async (dispatch) => {
    api(`/profiles/${id}/`, {
      method: "PATCH",
      data: fields,
    })
      .then((res) => {
        dispatch(editUserProfile(res.data));
        enqueueSnackbar(i18n.t("profile>Profile edited successfully!"), {
          variant: "success",
        });
        setShow(false);
      })
      .catch((err) => {
        enqueueSnackbar(i18n.t("profile>Incorrect fields"), {
          variant: "error",
        });
        let errData = err.response.data
        console.log(errData)
        if (typeof errData === 'object' && errData !== null){
          setErrors(errData);
          dispatch(userProfileError(i18n.t("validation>There are errors in the form.")));
        } else {
          dispatch(userProfileError(errData));
        } 
      });
  };
}

export function deleteUserProfile(id) {
  return async (dispatch) => {
    api(`/profiles/${id}/`, {
      method: "DELETE",
    })
      .then(() => {
        //const data = await res.json();
        dispatch(deleteProfile(id));
      })
      .then(() => {
        window.location = "/";
        dispatch(logOutUser());
      })
      .catch((err) => {
        dispatch(userProfileError());
        console.log(err.message);
        window.location = "/";
      });
  };
}

export function partialEditUserInfo(id, bioText) {
  return async (dispatch) => {
    api(`/profiles/${id}/`, {
      method: "PATCH",
      data: bioText,
    })
      .then((res) => {
        dispatch(editUserProfile(res.data));
      })
      .catch((err) => {
        dispatch(userProfileError());
        console.log(err.message);
      });
  };
}
