// Redux functionality for Top Rated Mini Books.

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  hasErrors: false,
  miniBook: [],
  content: "",
  steps: {},
  errorMsg: {},
  currentPage: 1,
  pageMaximum: 1,
  pageChanged: false,
  pageArray: [],
  readActionError: "",
};

export const miniBookSlice = createSlice({
  name: "miniBook",
  initialState,
  reducers: {
    getOneMiniBook: (state, { payload }) => {
      state.miniBook = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setContent: (state, { payload }) => {
      state.content = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    setSteps: (state, { payload }) => {
      state.steps = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    miniBookError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setPageMaximum: (state, { payload }) => {
      state.pageMaximum = payload;
    },
    setPageChanged: (state, { payload }) => {
      state.pageChanged = payload;
    },
    setPageArray: (state, { payload }) => {
      state.pageArray = payload;
    },
    setReadActionError: (state, { payload }) => {
      state.readActionError = payload;
    },
  },
});

export const {
  getOneMiniBook,
  setContent,
  setSteps,
  miniBookError,
  setCurrentPage,
  setPageMaximum,
  setPageChanged,
  setPageArray,
  setReadActionError,
} = miniBookSlice.actions;

export const selectMiniBook = (state) => state.miniBook;

export default miniBookSlice.reducer;

export function fetchOneMiniBook(slug) {
  return async (dispatch) => {
    api(`/minibooks/${slug}/`)
      .then((res) => {
        dispatch(getOneMiniBook(res.data));
      })
      .catch((err) => {
        dispatch(miniBookError(err.message));
      });
  };
}

export function fetchOneMiniBookContent(slug) {
  return async (dispatch) => {
    api
      .post(`/minibooks/${slug}/get_steps/`, { steps: 0 })
      .then((res) => {
        dispatch(setContent(res.data.content));
      })
      .catch((err) => {
        dispatch(miniBookError(err.message));
      });
  };
}

export function fetchOneMiniBookSteps(slug, array) {
  return async (dispatch) => {
    api
      .post(`/minibooks/${slug}/get_steps/`, { steps: array })
      .then((res) => {
        dispatch(setSteps(res.data));
      })
      .catch((err) => {
        dispatch(miniBookError(err.message));
      });
  };
}

export const createReadAction = (slug) => {
  return async (dispatch) => {
    api(`/minibooks/${slug}/create_user_read_action/`, {
      method: "POST",
    })
      .then(() => {})
      .catch((err) => {
        dispatch(setReadActionError(err.response.data));
      });
  };
};
