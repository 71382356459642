// Redux functionality to manage ad banners

import { createSlice } from "@reduxjs/toolkit";
import api from "../axios";

export const initialState = {
  adSenseId: null,
  affiliateLinks: [],
  hasErrors: false,
  errorMsg: {},
};

export const adBannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    getAdsenseId: (state, { payload }) => {
      state.adSenseId = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    getAffiliateLinks: (state, { payload }) => {
      state.affiliateLinks = payload;
      state.hasErrors = false;
      state.errorMsg = {};
    },
    deleteAffiliateLink: (state, { payload }) => {
      state.affiliateLinks = state.affiliateLinks.filter(
        (affiliateLink) => affiliateLink.id !== payload
      );
      state.hasErrors = false;
      state.errorMsg = {};
    },
    bannerError: (state, { payload }) => {
      state.hasErrors = true;
      state.errorMsg = payload;
    },
  },
});

// Create actions

export const {
  getAdsenseId,
  getAffiliateLinks,
  deleteAffiliateLink,
  bannerError,
} = adBannerSlice.actions;

// Add payload to state

export const selectBanners = (state) => state.banners;

export default adBannerSlice.reducer;

export function fetchAdsenseId() {
  return async (dispatch) => {
    api("/frontend_admin/adsense_id/", {
      method: "GET",
    })
      .then((res) => {
        dispatch(getAdsenseId(res));
      })
      .catch((err) => {
        dispatch(bannerError(err.message));
      });
  };
}

export function fetchAffiliateLinks() {
  return async (dispatch) => {
    api("/frontend_admin/affiliate_links/", {
      method: "GET",
    })
      .then((res) => {
        dispatch(getAffiliateLinks(res.data.results));
      })
      .catch((err) => {
        dispatch(bannerError(err.message));
      });
  };
}

export function deleteOneAffiliateLink(id) {
  return async (dispatch) => {
    api(`/frontend_admin/affiliate_links/${id}/`, {
      method: "DELETE",
    })
      .then(() => {
        dispatch(deleteAffiliateLink(id));
      })
      .catch((err) => {
        dispatch(bannerError(err.message));
      });
  };
}
